// exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);

// function scrollToAnchor(location, mainNavHeight = 0) {
//   // Check for location so build does not fail
//   if (location && location.hash) {
//     const item = document.querySelector(`${location.hash}`).offsetTop;
//     const y = window.screenY || window.screenTop;
//     console.log(
//       { mainNavHeight },
//       { item },
//       { y },
//       item - y,
//       item - mainNavHeight,
//       "browser3"
//     );
//     window.scrollTo({
//       top: 1000 - y,
//       behavior: "smooth"
//     });
//   }
//   return true;
// }

exports.shouldUpdateScroll = ({ location }) => {
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop;
    console.log("browser3");
    window.scrollTo({
      top: 4000,
      behavior: "smooth"
    });
  }
  console.log("アップデート中4");
};
