import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { navigate } from "@reach/router";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../css/animation.css";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      newpointt: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menuBar: file(relativePath: { eq: "menuBotton1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "header_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "orangeContact.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hamburgerBar: file(relativePath: { eq: "hamburgerBar.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      orangeClose: file(relativePath: { eq: "orangeClose.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      round: file(relativePath: { eq: "round2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mitu: file(relativePath: { eq: "mitui.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const AncorLink = (props) => {
    const { hostPath = "/", to = "/", hash = "" } = props;
    const hashs = [
      "place",
      "mirabura",
      "pop-up-store",
      "news",
      "contact",
      "team",
    ];
    const ancroPath = hashs[hashs.indexOf(hash) + 1];

    const url = typeof window !== "undefined" ? window.location.pathname : "";

    return (
      <>
        {url === hostPath ? (
          <Link to={to + "#" + hash}>{props.children}</Link>
        ) : (
          <a href={to + "#" + ancroPath}>{props.children}</a>
        )}
      </>
    );
  };
  return (
    <>
      <Hidden smDown>
        <Box
          width="100%"
          mx={"auto"}
          className="tac df"
          zIndex={99}
          position="fixed"
          display="flex"
          justifyContent="flex-end"
        >
          <Box position="absolute" left="16px" top="16px">
            <Link to="/">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                style={{ width: "109px" }}
              />
            </Link>
          </Box>
          <Box
            width="900px"
            display="flex"
            justifyContent="space-around"
            className="tac"
            mt="20px"
          >
            <Box className="df aic">
              <AnchorLink to="/#place" title="Our team" stripHashs>
                <WrapTypo Luam fw="700" ls="2px" fs="20px">
                  PLACE
                </WrapTypo>
              </AnchorLink>
            </Box>
            <Box className="df aic">
              <AnchorLink to="/#mirabura">
                <WrapTypo Luam fw="700" ls="2px" fs="20px">
                  MIRABURA
                </WrapTypo>
              </AnchorLink>
            </Box>
            <Box className="df aic">
              <AnchorLink to="/#pop-up-store">
                <WrapTypo Luam fw="700" ls="2px" fs="20px">
                  POP-UP STORE
                </WrapTypo>
              </AnchorLink>
            </Box>
            <Box className="df aic">
              <AncorLink
                to="https://www.mitsuifudosan.co.jp/"
                hash="news"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WrapTypo Luam fw="700" ls="2px" fs="20px">
                  NEWS
                </WrapTypo>
              </AncorLink>
            </Box>
            <Box className="df aic">
              <Link to="/#team">
                <WrapTypo Luam fw="700" ls="2px" fs="20px">
                  TEAM
                </WrapTypo>
              </Link>
            </Box>
            <Box display="flex" alignItems="center">
              <Link
                to="https://www.mitsuifudosan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.mitu.childImageSharp.fluid}
                  style={{ width: "94px" }}
                />
              </Link>
            </Box>
            <Box>
              <Box width="82px" height="64px">
                {isOpen ? (
                  <>
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Img
                        fluid={data.hamburgerBar.childImageSharp.fluid}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Img
                        fluid={data.hamburgerBar.childImageSharp.fluid}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box width="90%" m={"auto"} pt="16px">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <WrapTypo isCooperItalic>
                <Link to="/" style={{ color: "#717171" }}>
                  <Box position="relative" zIndex={1}>
                    <Img
                      fluid={data.logo.childImageSharp.fluid}
                      className="w40"
                    />
                  </Box>
                </Link>
              </WrapTypo>
            </Grid>
            <Grid item xs={2}>
              {isOpen ? (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen((c) => !c)}
                >
                  <Box style={{ width: "50%", maxWidth: "40px" }}>
                    <Img fluid={data.close.childImageSharp.fluid} />
                  </Box>
                </Button>
              ) : (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen((c) => !c)}
                >
                  <Box style={{ width: "100%", maxWidth: "40px" }}>
                    <Img fluid={data.hamburgerBar.childImageSharp.fluid} />
                  </Box>
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Drawer
        anchor={"left"}
        open={isOpen}
        style={{ width: "100%" }}
        transitionDuration="1500"
      >
        <Box width="100vw" height="100vh" bgcolor="#F7F6F1">
          <Hidden smDown>
            <Box
              width="100%"
              mx={"auto"}
              className="tac df"
              zIndex={99}
              position="fixed"
              display="flex"
              justifyContent="flex-end"
            >
              <Box position="absolute" left="16px" top="20px">
                <Box
                  width={["69px", "69px", "109px"]}
                  height={["49px", "49px", "80.66px"]}
                >
                  <WrapTypo isCooperItalic>
                    <Link
                      to="https://www.mitsuifudosan.co.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#717171" }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Img
                        fluid={data.logo.childImageSharp.fluid}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Link>
                  </WrapTypo>
                </Box>
              </Box>
              <Box
                width="780px"
                display="flex"
                justifyContent="space-around"
                className="tac"
                mt="20px"
              >
                <Box className="df aic">
                  <WrapTypo Luam fw="700" ls="2px" fs="20px" color="#F7F6F1">
                    PLACE
                  </WrapTypo>
                </Box>
                <Box className="df aic">
                  <WrapTypo Luam fw="700" ls="2px" fs="20px" color="#F7F6F1">
                    MIRABURA
                  </WrapTypo>
                </Box>
                <Box className="df aic">
                  <WrapTypo Luam fw="700" ls="2px" fs="20px" color="#F7F6F1">
                    POP-UP STORE
                  </WrapTypo>
                </Box>
                <Box className="df aic">
                  <WrapTypo Luam fw="700" ls="2px" fs="20px" color="#F7F6F1">
                    NEWS
                  </WrapTypo>
                </Box>
                <Box className="df aic">
                  <WrapTypo Luam fw="700" ls="2px" fs="20px" color="#F7F6F1">
                    TEAM
                  </WrapTypo>
                </Box>
                <Box width="82px" height="64px" display="flex">
                  {isOpen ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        style={{
                          width: "100%",
                        }}
                        onClick={() => setOpen((c) => !c)}
                      >
                        <Box
                          width={["22px", "22px", "40px"]}
                          height={["17px", "17px", "22px"]}
                        >
                          <Img
                            fluid={data.orangeClose.childImageSharp.fluid}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        maxWidth: "126px",
                        margin: "auto",
                      }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Box style={{ width: "70%", maxWidth: "40px" }}>
                        <Img fluid={data.menuBar.childImageSharp.fluid} />
                      </Box>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box width="90%" margin="auto" pt="16px">
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <WrapTypo isCooperItalic>
                    <Link
                      to="https://www.mitsuifudosan.co.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#717171" }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Img
                        fluid={data.logo.childImageSharp.fluid}
                        className="w40"
                      />
                    </Link>
                  </WrapTypo>
                </Grid>
                <Grid item xs={2}>
                  {isOpen ? (
                    <Button
                      style={{
                        width: "100%",
                        maxWidth: "126px",
                        margin: "auto",
                      }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Box style={{ width: "50%", maxWidth: "20px" }}>
                        <Img fluid={data.close.childImageSharp.fluid} />
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        maxWidth: "126px",
                        margin: "auto",
                      }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Box style={{ width: "70%", maxWidth: "40px" }}>
                        <Img fluid={data.menuBar.childImageSharp.fluid} />
                      </Box>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Box margin="auto">
            <Hidden mdUp>
              <Box
                bgcolor="#F7F6F1"
                width="100%"
                height="600px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="tac"
                py={4}
                style={{ flexFlow: "column" }}
              >
                <Box>
                  <Box
                    style={{ width: "68px", hight: "68px" }}
                    margin="auto"
                    id="rotationAnimation1"
                  >
                    <Img
                      fluid={data.round.childImageSharp.fluid}
                      style={{ width: "100%", hight: "100%" }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#place">
                    <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="20px">
                      PLACE
                    </WrapTypo>

                    <WrapTypo Notosans fs="10px">
                      提供する価値
                    </WrapTypo>
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#mirabura">
                    <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="20px">
                      MIRABURA
                    </WrapTypo>
                    <WrapTypo Notosans fs="10px">
                      みらブラ紹介
                    </WrapTypo>
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#pop-up-store">
                    <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="20px">
                      POP-UP STORE
                    </WrapTypo>
                    <WrapTypo Notosans fs="10px">
                      ポップアップストア
                    </WrapTypo>
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#news">
                    <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="20px">
                      NEWS
                    </WrapTypo>
                    <WrapTypo Notosans fs="10px">
                      最新情報
                    </WrapTypo>
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#team">
                    <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="20px">
                      TEAM
                    </WrapTypo>
                    <WrapTypo Notosans fs="10px">
                      チーム紹介
                    </WrapTypo>
                  </Link>
                </Box>
                <Box display="flex" alignItems="center" margin="20px 0">
                  <Link
                    to="https://www.mitsuifudosan.co.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fluid={data.mitu.childImageSharp.fluid}
                      style={{ width: "94px" }}
                    />
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => setOpen((c) => !c)} to="/#contact">
                    <Box
                      style={{ width: "104px", hight: "80px" }}
                      margin="auto"
                    >
                      <Img
                        fluid={data.contact.childImageSharp.fluid}
                        style={{ width: "100%", hight: "100%" }}
                      />
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Hidden>
            <Hidden smDown>
              <Box height="70%">
                <Box
                  marginTop="120px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Box width="100%">
                    <Grid container direction="row">
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4}>
                        <Box
                          height="100%"
                          display="flex"
                          justifyContent="space-between"
                          style={{ flexFlow: "column" }}
                        >
                          <Box style={{ width: "280px", hight: "116px" }}>
                            <Img
                              fluid={data.newpointt.childImageSharp.fluid}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#contact"
                            >
                              <Box style={{ width: "158px", hight: "122px" }}>
                                <Img
                                  fluid={data.contact.childImageSharp.fluid}
                                  style={{ width: "100%", hight: "100%" }}
                                />
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box
                          bgcolor="#F7F6F1"
                          width="100%"
                          height="450px"
                          display="flex"
                          justifyContent="space-between"
                          style={{ flexFlow: "column" }}
                        >
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#place"
                            >
                              <WrapTypo
                                Luam
                                fw="700"
                                ls="2px"
                                fs="68px"
                                lh="100%"
                              >
                                PLACE
                              </WrapTypo>
                            </Link>
                          </Box>
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#mirabura"
                            >
                              <WrapTypo
                                Luam
                                fw="700"
                                ls="2px"
                                mt="16px"
                                fs="68px"
                                lh="100%"
                              >
                                MIRABURA
                              </WrapTypo>
                            </Link>
                          </Box>
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#pop-up-store"
                            >
                              <WrapTypo
                                Luam
                                fw="700"
                                ls="2px"
                                mt="16px"
                                fs="68px"
                                lh="100%"
                              >
                                POP-UP STORE
                              </WrapTypo>
                            </Link>
                          </Box>
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#news"
                            >
                              <WrapTypo
                                Luam
                                fw="700"
                                ls="2px"
                                mt="16px"
                                fs="68px"
                                lh="100%"
                              >
                                NEWS
                              </WrapTypo>
                            </Link>
                          </Box>
                          <Box>
                            <Link
                              onClick={() => setOpen((c) => !c)}
                              to="/#team"
                            >
                              <WrapTypo
                                Luam
                                fw="700"
                                ls="2px"
                                mt="16px"
                                fs="68px"
                                lh="100%"
                              >
                                TEAM
                              </WrapTypo>
                            </Link>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          display="flex"
                          alignItems="flex-end"
                          height="100%"
                          position="relative"
                        >
                          <Box
                            position="absolute"
                            bottom="-100px"
                            right="10px"
                            style={{ width: "145px", hight: "145px" }}
                            id="rotationAnimation1"
                          >
                            <Img
                              fluid={data.round.childImageSharp.fluid}
                              style={{ width: "100%", hight: "100%" }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
