import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      tw_logo: file(relativePath: { eq: "tw_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fb_logo: file(relativePath: { eq: "Facebook_Logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      in_logo: file(relativePath: { eq: "Instagram_logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      note_logo: file(relativePath: { eq: "note_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box bgcolor="#ffffff">
        <Box
          padding="80px 0 40px"
          minWidth="290px"
          maxWidth="354px"
          width="80%"
          margin="auto"
        >
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
          >
            <Box>
              <Grid item xs={3}>
                <Link to="">
                  <Box>
                    <Img
                      style={{
                        height: "clamp(23.92px, 10rem, 35px)",
                        width: "clamp(103.57px, 10rem, 106px)"
                      }}
                      fluid={data.note_logo.childImageSharp.fluid}
                    />
                  </Box>
                </Link>
              </Grid>
            </Box>
            <Box>
              <Grid item xs={3}>
                <Link to="">
                  <Box>
                    <Img
                      style={{
                        height: "clamp(23.92px, 10rem, 35.32px)",
                        width: "clamp(23.92px, 10rem, 35.32px)"
                      }}
                      fluid={data.in_logo.childImageSharp.fluid}
                    />
                  </Box>
                </Link>
              </Grid>
            </Box>
            <Box>
              <Grid item xs={3}>
                <Link to="/">
                  <Box>
                    <Img
                      style={{
                        height: "clamp(23.92px, 10rem, 35.32px)",
                        width: "clamp(23.92px, 10rem, 35.32px)"
                      }}
                      fluid={data.fb_logo.childImageSharp.fluid}
                    />
                  </Box>
                </Link>
              </Grid>
            </Box>
            <Box>
              <Grid item xs={3}>
                <Link to="/">
                  <Box>
                    <Img
                      style={{
                        height: "clamp(21.74px, 10rem, 36px)",
                        width: "clamp(38.58px, 10rem, 45px)"
                      }}
                      fluid={data.tw_logo.childImageSharp.fluid}
                    />
                  </Box>
                </Link>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Box padding="0 0 60px">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <WrapTypo
                isCooperItalic
                color="#696969"
                xsfs="14px"
                mdfs="20px"
                lgfs="20px"
              >
                test@test.com
              </WrapTypo>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
