import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap"
          rel="stylesheet"
        />
        <title>NEWPOINT | Official Website</title>
        {/* ogp */}
        <meta name="description" content="NEWPOINT" />
        <meta property="og:title" content="NEWPOINT|Official Website" />
        <meta
          property="og:description"
          content="新しい“購買体験”を生み出すプロジェクト『NEW POINT』みらいのD2Cブランド（通称「みらブラ」）と共に『NEW POINT』を通して、ワクワクする偶然の出会いをお届けします。"
        />
        <meta property="og:url" content="https://newpoint.info/" />
        <meta
          property="og:image"
          content="https://newpoint.info/images/ogp.png"
        />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
        {/* <script src="https://www.google.com/recaptcha/api.js?render=6LccsYEbAAAAABBvGTdI7cIpjJLD95LlloW3dpVh"></script> */}
        {/* ogp */}
      </Helmet>
    </>
  );
};

export default Head;
