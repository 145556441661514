import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";
import BoxTypo from "../components/BoxTypo";
import "../css/rotate_img.css";

// import LocationSp from "./LocationSp.svg";

function Popup() {
  const data = useStaticQuery(graphql`
    query {
      popupImg: file(relativePath: { eq: "popupImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      popupSideImg: file(relativePath: { eq: "popupSideImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spImgpopup: file(relativePath: { eq: "spImgpopup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sppopUpImgSide: file(relativePath: { eq: "sppopUpImgSide.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      popupBGimg: file(relativePath: { eq: "popupBGimg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fontImg: file(relativePath: { eq: "pcFont.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fontImg2: file(relativePath: { eq: "spFont.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      popupRound: file(relativePath: { eq: "popupRound.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miyashita_sp: file(relativePath: { eq: "miyashita_sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      publicURLsp: file(relativePath: { eq: "LocationSp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      newpointFactory: file(relativePath: { eq: "newpoint_factory.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      building_img: file(relativePath: { eq: "building_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      report_button: file(relativePath: { eq: "report_button.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_scroll1: file(relativePath: { eq: "sp_scrollcontent1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_scroll2: file(relativePath: { eq: "sp_scrollcontent2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc_scroll: file(relativePath: { eq: "pc_scrollcontent.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      publicURL: file(relativePath: { eq: "miraburaLocation.svg" }) {
        publicURL
      }
    }
  `);

  const { publicURL } = data.publicURL;
  const { spImage } = data.publicURLsp;
  return (
    <>
      <Box width="100%">
        <Box pb={11} position="relative">
          <Box
            position="absolute"
            top={["-100px", "-100px", "-180px"]}
            zIndex={0}
            width="100%"
            maxHeight="1500px"
          >
            <Img
              fluid={data.popupBGimg.childImageSharp.fluid}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Hidden smDown>
            <Box width="100%">
              <Box
                width={["90%", "90%", "80%"]}
                margin="auto"
                position="relative"
                zIndex="1"
              >
                <Box data-aos="fade-in">
                  <WrapTypo
                    Luam
                    fs="80px"
                    xsfs="40px"
                    mdfs="40px"
                    lgfs="80px"
                    ls="2px"
                    lh="200%"
                  >
                    POP-UP STORE
                  </WrapTypo>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="end"
                >
                  <Box width="45%">
                    <Img
                      fluid={data.newpointFactory.childImageSharp.fluid}
                      style={{
                        width: "60%",
                        margin: "auto",
                      }}
                    />
                    <BoxTypo
                      fs={["12px"]}
                      ls={["2px"]}
                      lh={["200%"]}
                      my={[6]}
                      color="#14274C"
                    >
                      RAYARD MIYASHITA
                      PARKにて3日間限定のポップアップイベントを実施いたしました。イベントでは、NEW
                      POINTが創り出す世界に浸り、『みらブラ』のアイテム販売や、『みらブラ』をさらに深く知って体感することができる体験ブースなどを、お楽しみいただきました。
                    </BoxTypo>
                    <Link
                      to="https://note.com/newpoint_pj/n/nfb4f6c887376"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={data.report_button.childImageSharp.fluid} />
                    </Link>
                  </Box>
                  <Box width="45%">
                    <Img
                      fluid={data.building_img.childImageSharp.fluid}
                      style={{ mixBlendMode: "darken" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box position="relative" zIndex="1">
              <Box width="90%" margin="auto" data-aos="fade-in">
                <Box>
                  <WrapTypo Luam fs="36px" ls="2px" lh="200%">
                    POP-UP STORE
                  </WrapTypo>
                </Box>
                <Box>
                  <Box width="100%">
                    <Img
                      fluid={data.newpointFactory.childImageSharp.fluid}
                      style={{
                        width: "80%",
                        margin: "auto",
                      }}
                    />
                    <Img
                      fluid={data.building_img.childImageSharp.fluid}
                      style={{ mixBlendMode: "darken", marginTop: "32px" }}
                    />
                    <BoxTypo
                      fs={["12px"]}
                      ls={["2px"]}
                      lh={["200%"]}
                      my={[3, 3, 6]}
                      color="#14274C"
                    >
                      RAYARD MIYASHITA
                      PARKにて3日間限定のポップアップイベントを実施いたしました。イベントでは、NEW
                      POINTが創り出す世界に浸り、『みらブラ』のアイテム販売や、『みらブラ』をさらに深く知って体感することができる体験ブースなどを、お楽しみいただきました。
                    </BoxTypo>
                    <Link
                      to="https://note.com/newpoint_pj/n/nfb4f6c887376"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={data.report_button.childImageSharp.fluid} />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Hidden>
        </Box>
        <Box className="rootBox" data-aos="fade-in">
          <Hidden smDown>
            <Box className="childRight" display="flex" width="20000px">
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
              <Img
                fluid={data.pc_scroll.childImageSharp.fluid}
                style={{ width: "2500px", marginLeft: "32px" }}
              />
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box className="spChildRight" display="flex" width="8000px" pb={5}>
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll1.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
            </Box>
            <Box className="childLeft" display="flex" width="8000px">
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
              <Img
                fluid={data.sp_scroll2.childImageSharp.fluid}
                style={{ width: "1000px", marginLeft: "16px" }}
              />
            </Box>
          </Hidden>
        </Box>
      </Box>
    </>
  );
}

export default Popup;
