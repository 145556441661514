import React from "react"
import { navigate } from "gatsby"
import { Grid, Box, Button } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useForm } from "react-hook-form"
import firebase from "../Firebase"
import Wraptypo from "../components/WrapTypo"
import { getThemeProps } from "@material-ui/styles"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export const ContactTitle = () => {
  return (
    <>
      <Box width="100%" maxWidth="1080px" margin="auto">
        <Box width="100%" padding="15px 30px">
          <Grid>
            <Wraptypo
              isCooperItalic
              color="#80A1DD"
              xsfs="18px"
              mdfs="46px"
              lgfs="46px"
            >
              CONTACT
            </Wraptypo>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
