import React, { useState } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WrapTypo from "./WrapTypo";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import firebase from "../Firebase";
import "../css/animation.css";
import BoxTypo from "../components/BoxTypo";

const isEmpty = (obj) => !Object.keys(obj).length;

const emailRule = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "「メールアドレス」の入力形式が正しくありません。",
  },
};
const phoneNumberRule = {
  minLength: {
    value: 6,
    message: "「電話番号」の入力文字数が少なすぎます",
  },
  maxLength: {
    value: 12,
    message: "「電話番号」の入力文字数が多すぎます",
  },
  pattern: {
    value: /^[0-9]+$/,
    message: "「電話番号」の入力形式が正しくありません",
  },
};

const textRule = (name) => ({
  maxLength: {
    value: 50,
    message: `「${name}」の入力文字数が多すぎます`,
  },
});

const contextRule = {
  maxLength: {
    value: 1000,
    message: "「内容」入力文字数が多すぎます",
  },
};

function Contact() {
  const [checked, setChecked] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      contactButtonImg: file(relativePath: { eq: "contactButtonImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactButtonImgGray: file(
        relativePath: { eq: "contactButtonImgGray.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      circle2: file(relativePath: { eq: "circle2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  // const onClickSubmit = e => {
  //   e.preventDefault();
  //   window.grecaptcha.ready(function () {
  //     window.grecaptcha
  //       .execute("6LccsYEbAAAAABBvGTdI7cIpjJLD95LlloW3dpVh", {
  //         action: "submit"
  //       })
  //       .then(function (token) {
  //         console.log(
  //           JSON.stringify({
  //             secret: "6LccsYEbAAAAANMf7RnmebZtSdEMsczO-RMNpYRL",
  //             response: token
  //           })
  //         );
  //         console.log({ token });
  //         fetch("https://www.google.com/recaptcha/api/siteverify", {
  //           method: "POST",
  //           mode: "no-cors",
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Access-Control-Allow-Origin": "*",
  //             secret: "6LccsYEbAAAAANMf7RnmebZtSdEMsczO-RMNpYRL"
  //           },
  //           body: JSON.stringify({
  //             secret: "6LccsYEbAAAAANMf7RnmebZtSdEMsczO-RMNpYRL",
  //             response: token
  //           })
  //         })
  //           .then(t => console.log(t))
  //           .catch(e => console.log({ e }));
  //       });
  //   });
  // };

  const onSubmit = (values) => {
    setLoading(true);
    const docId = firebase.firestore().collection("contacts").doc().id;
    firebase
      .firestore()
      .collection("contacts")
      .doc(docId)
      .set({
        ...values,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((t) => {
        navigate("/contacts-result/", { state: { result: "success" } });
        setLoading(false);
      })
      .catch((e) => {
        navigate("/contacts-result/", { state: { result: "faild" } });
        setLoading(false);
      });
  };
  const errKeys = Object.keys(errors);

  return (
    <>
      <Box
        bgcolor="#F7F6F1"
        paddingTop={["60px", "60px", "100px"]}
        paddingBottom={["60px", "60px", "100px"]}
      >
        <form onSubmit={handleSubmit(onSubmit)} id="newpoint-form">
          <Box width={["90%", "90%", "80%"]} margin="auto" position="relative">
            <Box>
              <WrapTypo
                Luam
                fs="80px"
                xsfs="40px"
                mdfs="40px"
                lgfs="80px"
                ls="2px"
                lh="200%"
                s
              >
                CONTACT
              </WrapTypo>
            </Box>
            <Box maxWidth="900px" mb="39px">
              <Grid container>
                <Grid item xs={12} sm={8}>
                  <Box bgcolor="#FFFFFF" padding="24px 16px">
                    <Box>
                      <WrapTypo fs="14px" color="#14274C">
                        ご入力前に「
                        <Link
                          to="https://www.mitsuifudosan.co.jp/privacy_policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "skyblue" }}
                        >
                          個人情報保護方針
                        </Link>
                        」と「
                        <Link
                          to="https://newpoint.web.app/personal-information.html"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "skyblue" }}
                        >
                          個人情報の取り扱いについて
                        </Link>
                        」をご確認ください。
                        <ol style={{ margin: "16px auto 32px 5%" }}>
                          <li style={{ marginTop: "16px" }}>
                            お客様からご提供いただく個人情報は、当社「個人情報保護方針」および「個人情報の取り扱いについて」に基づき管理いたします。
                          </li>
                          <li style={{ marginTop: "16px" }}>
                            当社がお問い合わせフォームで取得した、お客様の個人情報は、お客様への回答の目的のみに使用いたしますが、
                            お問い合わせ内容により、当社グループ会社で対応させていただく場合がございます
                            その場合、当社グループ会社に、お客様の個人情報を、提供することがございますので、あらかじめご了承ください。
                          </li>
                          <li style={{ marginTop: "16px" }}>
                            当社がお客様へ回答する内容の一部または全部を、お客様が転載、二次利用することはできません。
                          </li>
                          <li style={{ marginTop: "16px" }}>
                            土日祝日にいただいたお問い合わせについては、翌営業日以降の回答となりますので、あらかじめご了承ください。
                          </li>
                          <li style={{ marginTop: "16px" }}>
                            商品・サービス等の営業に関するお問い合わせについては返信いたしかねますのでご了承ください。
                          </li>
                        </ol>
                        2021年9月7日
                      </WrapTypo>
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => {
                            setChecked(!checked);
                          }}
                        />
                      }
                      label="同意する"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box maxWidth="900px">
              <Grid container>
                <InputFormItem
                  title="お名前"
                  placeholder="田中太郎"
                  name="name"
                  requiredItemFlag={true}
                  register={register}
                  rule={textRule("お名前")}
                />
                <InputFormItem
                  title="会社名"
                  placeholder="三井不動産株式会社"
                  name="company"
                  requiredItemFlag={true}
                  register={register}
                  rule={textRule("会社名")}
                />
                <InputFormItem
                  title="ブランド名"
                  placeholder="NEWPOINT"
                  name="brand"
                  requiredItemFlag={true}
                  register={register}
                  rule={textRule("ブランド名")}
                />
                <InputFormItem
                  title="電話番号"
                  placeholder="08012345678"
                  name="tel"
                  requiredItemFlag={true}
                  register={register}
                  rule={phoneNumberRule}
                />
                <InputFormItem
                  title="メールアドレス"
                  placeholder="mirabura@newpoint.jp"
                  name="mail"
                  requiredItemFlag={true}
                  register={register}
                  rule={emailRule}
                />
                <TextAreaFormItem
                  title="お問い合わせ内容"
                  placeholder="お問い合わせ内容が入ります"
                  name="content"
                  requiredItemFlag={true}
                  register={register}
                  rule={contextRule}
                />
              </Grid>
            </Box>
            {!isEmpty(errors) && errKeys.length > 0 && (
              <Box mb={2}>
                <Alert severity="error">
                  {errKeys.map((k) => (
                    <React.Fragment key={k}>
                      ・{errors[k].message}
                      <br />
                    </React.Fragment>
                  ))}
                </Alert>
              </Box>
            )}
            <Box width={["95px", "95px", "145px"]} margin="auto">
              {!checked ? (
                <>
                  <Img
                    fluid={data.contactButtonImgGray.childImageSharp.fluid}
                    style={{ width: "100%", filter: "grayscale(50%)" }}
                  />
                </>
              ) : (
                <Button type="submit" style={{ width: "100%", padding: 0 }}>
                  <Img
                    fluid={data.contactButtonImg.childImageSharp.fluid}
                    style={{ width: "100%" }}
                  />
                </Button>
              )}
            </Box>
            <Hidden xsDown>
              <Box
                type="submit"
                width="160px"
                position="absolute"
                top="40px"
                right="0"
                id="rotationAnimation3"
              >
                <Img
                  fluid={data.circle2.childImageSharp.fluid}
                  style={{ width: "100%" }}
                />
              </Box>
            </Hidden>
          </Box>
        </form>
      </Box>
    </>
  );
}

const NotRequiredInputFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  requiredItemFlag,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box mb={[2, 2, 4]}>
            <WrapTypo xsfs="10px" mdfs="10px" lgfs="16px" Aotf fs="16px">
              {title}
              <span
                style={
                  requiredItemFlag ? { color: "red" } : { display: "none" }
                }
              >
                *
              </span>
            </WrapTypo>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box width={["100%", "100%", "100%"]} mb={[2, 2, 4]}>
            <input
              type="text"
              name={name}
              ref={register({
                ...rule,
              })}
              placeholder={placeholder}
              style={{
                height: "40px",
                fontSize: "16px",
                width: "100%",
                border: "0",
                fontSize: "14px",
                padding: "0 15px",
                border: "solid 1px #14274C",
                fontFamily:
                  "A-OTF-GothicMB101Pr6N-Med,Noto Sans JP, sans-serif",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const InputFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  requiredItemFlag,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box mb={[2, 2, 4]}>
            <WrapTypo xsfs="10px" mdfs="10px" lgfs="16px" fs="16px">
              {title}
              <span
                style={
                  requiredItemFlag ? { color: "red" } : { display: "none" }
                }
              >
                *
              </span>
            </WrapTypo>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box width={["100%", "100%", "100%"]} mb={[2, 2, 4]}>
            <input
              type="text"
              name={name}
              ref={register({
                required: `「${title}」の項目が未選択です`,
                ...rule,
              })}
              placeholder={placeholder}
              style={{
                height: "40px",
                fontSize: "16px",
                width: "100%",
                border: "0",
                fontSize: "14px",
                padding: "0 15px",
                border: "solid 1px #14274C",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const TextAreaFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  requiredItemFlag,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box mb={[2, 2, 4]}>
            <WrapTypo xsfs="10px" mdfs="10px" lgfs="16px" fs="16px">
              {title}
              <span
                style={
                  requiredItemFlag ? { color: "red" } : { display: "none" }
                }
              >
                *
              </span>
            </WrapTypo>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box mb={[2, 2, 4]}>
            <textarea
              name={name}
              ref={register({
                required: `「${title}」の項目が未入力です`,
                ...rule,
              })}
              placeholder={placeholder}
              style={{
                height: "100px",
                fontSize: "16px",
                width: "100%",
                border: "0",
                fontSize: "14px",
                padding: "10px 15px",
                border: "solid 1px #14274C",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
