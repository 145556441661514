import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";

function Team() {
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "teamImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamImg: file(relativePath: { eq: "image.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamBg: file(relativePath: { eq: "teamBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Box bgcolor="#F7F6F1" position="relative">
        <Box position="absolute" top="0" zIndex={0} width="100%" height="100%">
          <Img
            fluid={data.teamBg.childImageSharp.fluid}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box pt={[6, 6, 8]} pb={[11, 11, 13]}>
          <Box width={["90%", "80%"]} margin="auto" position="relative">
            <Box data-aos="fade-in">
              <WrapTypo
                Luam
                fs="80px"
                xsfs="40px"
                mdfs="40px"
                lgfs="80px"
                ls="2px"
                lh="200%"
              >
                TEAM
              </WrapTypo>
            </Box>
            <Box data-aos="fade-in">
              <Img fluid={data.photo.childImageSharp.fluid} />
              <Box textAlign="left" mt={3}>
                <WrapTypo fs="10px" ls="2px" lh="180%">
                  NEW
                  POINTのプロジェクトチームです。各領域のプロが専門チームを組み、みらブラや、今ここにいる皆様と一緒に“みらい“を創っていきます。
                </WrapTypo>
              </Box>
            </Box>

            {/* <Hidden xsDown>
              <Box
                width="170px"
                position="absolute"
                bottom="-39px"
                right="-28px"
              >
                <Link to="/team">
                  <Img fluid={data.teamImg.childImageSharp.fluid} />
                </Link>
              </Box>
            </Hidden>
            <Hidden smUp>
              <Link to="/team">
                <Box
                  width="100px"
                  position="absolute"
                  bottom="-50px"
                  right="-5px"
                >
                  <Img fluid={data.teamImg.childImageSharp.fluid} />
                </Box>
              </Link>
            </Hidden> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Team;
