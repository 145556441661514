import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";
import "../css/animation.css";

function Mirabura() {
  const data = useStaticQuery(graphql`
    query {
      Bg: file(relativePath: { eq: "BGImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 8000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pcImg: file(relativePath: { eq: "pcImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactImg: file(relativePath: { eq: "contactImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactBuBg: file(relativePath: { eq: "contactBuBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactImageSp: file(relativePath: { eq: "contactImageSp.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spImg: file(relativePath: { eq: "spImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc1: file(relativePath: { eq: "miraburaPc1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uni: file(relativePath: { eq: "uni.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc2: file(relativePath: { eq: "iamge2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc3: file(relativePath: { eq: "image33.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc4: file(relativePath: { eq: "image44.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc5: file(relativePath: { eq: "image555.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPc6: file(relativePath: { eq: "image66.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaPcLink: file(relativePath: { eq: "miraburaPcLink.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miraburaSpLink: file(relativePath: { eq: "spButton.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arrow: file(relativePath: { eq: "arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const buttonImg = data.miraburaPcLink.childImageSharp.fluid;
  const buttonSpImg = data.miraburaSpLink.childImageSharp.fluid;

  const miraburaContent = [
    {
      pcImg: data.uni.childImageSharp.fluid,
      link: "https://kitasanrikufactory.co.jp/",
      place: "岩手県洋野町",
      title: "株式会社北三陸ファクトリー",
      text:
        "「北三陸を世界に発信する。」をミッションに掲げ、北三陸の自然と人が生み出す最高の食材を国内外に発信し、持続可能な地域と水産業の未来を創造するブランドです。",
      delay: "100",
    },
    {
      pcImg: data.miraburaPc2.childImageSharp.fluid,
      link: "https://kyushu-pancake.jp",
      place: "宮崎県宮崎市",
      title: "株式会社九州テーブル",
      text:
        "「豊かな九州の自然や農業をものづくりに生かす。」 という理念のもと、九州各地を巡りながら出会った生産者さんや工場の皆さんと一緒に、九州の未来に残していける豊かなものづくりを続けるブランドです。",
      delay: "200",
    },
    {
      pcImg: data.miraburaPc3.childImageSharp.fluid,
      link: "https://www.takaki-nori.com/",
      place: "神奈川県川崎市",
      title: "株式会社高喜商店",
      text:
        "創業明治三十四年。原料仕入から製造販売まで全ての工程を自社で行う「自社製造一貫管理」を実現する海苔問屋です。伝統と技と誇りで、こだわりの海苔を私たちの食卓へ届けてくれるブランドです。",
      delay: "300",
    },
    {
      pcImg: data.miraburaPc4.childImageSharp.fluid,
      link: "https://www.osocu.jp/",
      place: "愛知県名古屋市",
      title: "OSOCU",
      text:
        "日本の伝統工芸をはじめとする、長年受け継がれてきた技術や素材を日常アイテムに落とし込む活動をしているブランドです。生産背景の透明性やローカルを活かす服作りを心掛けています。",
      delay: "400",
    },
    {
      pcImg: data.miraburaPc5.childImageSharp.fluid,
      link: "https://mebuki2021.base.shop/",
      place: "群馬県太田市",
      title: "mebuki",
      text:
        "群馬県太田市のニットを使い「どんな時でもあなたの人生が芽の成長のように自由になってほしい」その想いを届けるために誕生したアパレルブランドです。",
      delay: "500",
    },
    {
      pcImg: data.miraburaPc6.childImageSharp.fluid,
      link: "https://kakimori.com/",
      place: "東京都蔵前",
      title: "カキモリ",
      text:
        "「たのしく書く」きっかけをつくる文具店。世界に一つのオーダーノートやインク、書くことが楽しくなる道具と出会えるブランドです。",
      delay: "600",
    },
  ];

  const miraburaUnderContent = [
    {
      pcImg: data.pcImg.childImageSharp.fluid,
      spImg: data.spImg.childImageSharp.fluid,
      link: "",
      text:
        "NEW POINTで私たちと一緒に、<br />「未来を、もっとカラフルに。」したいと思っていただけたら、お問い合わせボタンよりご連絡ください。 お話できる日を、楽しみにしています。",
    },
  ];

  return (
    <>
      <Box position="relative">
        <Box
          bgcolor="#FCFAF7"
          position="absolute"
          top="-50px"
          zIndex={0}
          width="100%"
        >
          <Img
            fluid={data.Bg.childImageSharp.fluid}
            style={{ width: "100%" }}
          />
        </Box>
        <Box pt={8}>
          <Box width={["90%", "80%"]} margin="auto" position="relative">
            <Box data-aos="fade-in">
              <Box mb={4} whiteSpace={["normal", "normal", "pre-line"]}>
                <WrapTypo
                  Luam
                  fs="80px"
                  xsfs="40px"
                  mdfs="40px"
                  lgfs="80px"
                  ls="2px"
                  lh="150%"
                >
                  MIRABURA
                </WrapTypo>
                <WrapTypo
                  fs="14px"
                  xsfs="10px"
                  mdfs="10px"
                  lgfs="14px"
                  ls="2px"
                  lh="200%"
                  style={{ textAlign: "justify" }}
                >
                  {`NEW POINTを一緒に彩っていく『みらブラ』を紹介します。
                  『みらブラ』とは、"みらいのD2Cブランド"のこと。今ここにいる皆様と一緒に、豊かで潤いのある"みらい"を創っていくブランドです。`}
                </WrapTypo>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box bgcolor={["#F7F6F1", "#F7F6F1", "transparent"]}>
        <Box width={["90%", "80%"]} margin="auto">
          <Box pb={[3, 3, 6]} bgcolor="#F7F6F1" maxWidth="1200px" margin="auto">
            <Grid container spacing={3}>
              {miraburaContent.map((pic) => {
                return (
                  <MiraburaCard
                    img={pic.pcImg}
                    title={pic.title}
                    text={pic.text}
                    link={pic.link}
                    place={pic.place}
                    buttonImg={buttonImg}
                    spButtonImg={buttonSpImg}
                    arrow={data.arrow.childImageSharp.fluid}
                    delay={pic.delay}
                  />
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box bgcolor="#F7F6F1">
        <Box
          width={["90%", "80%", "80%"]}
          margin="auto"
          position="relative"
          zIndex="1"
        >
          <Hidden mdDown>
            <Box data-aos="fade-in" data-aos-duration="3000">
              <Box
                border={1}
                borderColor="1A2D51"
                display="flex"
                borderRadius={16}
              >
                <Box
                  width="100%"
                  margin="32px"
                  position="relative"
                  border={1}
                  borderRadius={16}
                >
                  <Img
                    fluid={miraburaUnderContent[0].pcImg}
                    style={{ width: "100%", borderRadius: "15px" }}
                  />
                  <Link to="/#contact">
                    <Box position="absolute" bottom="-25px" right="80px">
                      <Box position="relative" className="b">
                        <Box className="b1">
                          <Img
                            fluid={data.contactImg.childImageSharp.fluid}
                            style={{ width: "148px" }}
                          />
                        </Box>
                        <Box className="b2">
                          <Img
                            fluid={data.contactBuBg.childImageSharp.fluid}
                            style={{ width: "148px" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box
                  marginRight="23px"
                  marginLeft="96px"
                  display="flex"
                  alignItems="center"
                >
                  <WrapTypo fs="20px" ls="2px" lh="200%">
                    NEW POINTで私たちと一緒に、
                    <br />
                    「未来を、もっとカラフルに。」したいと思っていただけたら、お問い合わせボタンよりご連絡ください。
                    お話できる日を、楽しみにしています。
                  </WrapTypo>
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Hidden lgUp xsDown>
            <Box data-aos="fade-in" data-aos-duration="3000">
              <Box
                border={1}
                borderColor="1A2D51"
                borderRadius={16}
                display="flex"
              >
                <Box width="100%" margin="32px" position="relative">
                  <Img
                    fluid={miraburaUnderContent[0].spImg}
                    style={{ width: "100%" }}
                  />
                  <Box position="absolute" bottom="-20px" right="-50px">
                    <Link to="/#contact">
                      <Img
                        fluid={data.contactImg.childImageSharp.fluid}
                        style={{ width: "148px" }}
                      />
                    </Link>
                  </Box>
                </Box>
                <Box marginRight="23px" display="flex" alignItems="center">
                  <WrapTypo fs="20px" ls="2px" lh="200%">
                    NEW POINTで私たちと一緒に、
                    <br />
                    「未来を、もっとカラフルに。」したいと思っていただけたら、お問い合わせボタンよりご連絡ください。
                    お話できる日を、楽しみにしています。
                  </WrapTypo>
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box data-aos="fade-in" data-aos-duration="3000">
              <Box border={1} borderColor="1A2D51" borderRadius={16}>
                <Box width="90%" padding="21px 0" margin="auto">
                  <Img
                    fluid={miraburaUnderContent[0].spImg}
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box width="90%" margin="auto" marginBottom="16px">
                  <WrapTypo fs="14px" ls="2px" lh="200%">
                    NEW POINTで私たちと一緒に、
                    「未来を、もっとカラフルに。」したいと思っていただけたら、お問い合わせボタンよりご連絡ください。
                    お話できる日を、楽しみにしています。
                  </WrapTypo>
                </Box>
                <Box marginBottom="16px" display="flex" justifyContent="center">
                  <Link to="/#contact">
                    <Img
                      fluid={data.contactImageSp.childImageSharp.fluid}
                      style={{ width: "161px" }}
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box pb={10} bgcolor="#F7F6F1"></Box>
    </>
  );
}

const MiraburaCard = ({
  title,
  text,
  link,
  img,
  buttonImg,
  spButtonImg,
  place,
  arrow,
  delay,
}) => {
  return (
    <>
      {/* PCver */}
      <Grid item xs={12} md={6} lg={4}>
        <Box
          border={1}
          borderColor="#1A2D51"
          borderRadius={16}
          width={["282px", "282px", "350px"]}
          height={["430px", "430px", "464px"]}
          margin="auto"
          position="relative"
          data-aos="fade-in"
          data-aos-duration="3000"
        >
          <Box borderBottom="1px solid #1A2D51" position="relative">
            <Box
              position="absolute"
              bottom="0"
              left="0"
              width={["93px", "93px", "125px"]}
              height={["18px", "18px", "28px"]}
              bgcolor="#F7F6F1"
              zIndex="1"
              borderTop="1px solid #1A2D51"
              borderRight="1px solid #1A2D51"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <WrapTypo
                xsfs="9px"
                mdfs="9px"
                lgfs="14px"
                fs="14px"
                ls="1px"
                lh="100%"
              >
                {place}
              </WrapTypo>
            </Box>
            <Box
              width={["280px", "280px", "100%"]}
              height={["205px", "205px", "100%"]}
            >
              <Img
                fluid={img}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "15px 15px 0 0",
                  objectFit: "fill",
                }}
              />
            </Box>
          </Box>
          <Box
            mt={["12px", "12px", "19px"]}
            display="flex"
            alignItems="center"
            height={["32px", "32px", "44px"]}
          >
            <Box width="92%" margin="auto">
              <WrapTypo
                xsfs="16px"
                mdfs="16px"
                lgfs="22px"
                fs="22px"
                ls="1px"
                lh="100%"
              >
                {title}
              </WrapTypo>
            </Box>
          </Box>
          <Box
            mb={["25px", "25px", "19px"]}
            display="flex"
            alignItems="center"
            height={["80px", "80px", "80px"]}
          >
            <Box width="90%" margin="auto">
              <WrapTypo
                xsfs="12px"
                mdfs="12px"
                lgfs="12px"
                fs="12px"
                ls="1px"
                lh="150%"
                className="taj"
              >
                {text}
              </WrapTypo>
            </Box>
          </Box>
          <Box width="100%" className="linkButtonAnimation">
            <Box
              width={["256px", "256px", "92%"]}
              margin="auto"
              className="linkButtonAnimation"
            >
              <Hidden smDown>
                <Box position="relative">
                  <Box
                    width="84px"
                    height="10px"
                    position="absolute"
                    top="27px"
                    left="230px"
                    style={{ overflow: "hidden" }}
                  >
                    <Box className="zoom-in" width="41px">
                      <Img fluid={arrow} style={{ width: "100%" }} />
                    </Box>
                  </Box>
                </Box>
              </Hidden>
              <Link to={link} target="_blank" rel="noopener noreferrer">
                <Hidden smDown>
                  <Img fluid={buttonImg} style={{ width: "100%" }} />
                </Hidden>
                <Hidden mdUp>
                  <Img fluid={spButtonImg} style={{ width: "100%" }} />
                </Hidden>
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Mirabura;
