import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";

function PowerdBy() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "image2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "image3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "image4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "image5.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LogoPoweredBy: file(relativePath: { eq: "logoPowerder.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const powerdByImage = [
    { img: data.image1.childImageSharp.fluid, width: "181px", height: "54px" },
    { img: data.image2.childImageSharp.fluid, width: "151px", height: "48px" },
    {
      img: data.image3.childImageSharp.fluid,
      width: "182.63px",
      height: "48.43px",
    },
    { img: data.image4.childImageSharp.fluid, width: "133px", height: "57px" },
    { img: data.image5.childImageSharp.fluid, width: "259px", height: "42px" },
  ];

  return (
    <>
      <Box bgcolor="#ffffff">
        <Box pt={8} pb={10}>
          <Box width={["70%"]} margin="auto">
            <Box data-aos="fade-in">
              <WrapTypo Luam fs="30px" ls="2px" lh="200%" className="tac">
                POWERD BY
              </WrapTypo>
            </Box>
            <Hidden smDown>
              <Box
                maxWidth="1160px"
                width="100%"
                margin="auto"
                data-aos="fade-in"
              >
                <Img fluid={data.LogoPoweredBy.childImageSharp.fluid} />
              </Box>
            </Hidden>
            {/* <Box
              display="flex"
              alignItems="flex-end"
              flexWrap="nowrap"
              style={{ justifyContent: "space-between" }}
            >
              {powerdByImage.map((pic) => {
                return (
                  <Box width="100%">
                    <Img fluid={pic.img} />
                  </Box>
                );
              })}
            </Box> */}
          </Box>
          <Hidden mdUp>
            <Box width="95%" margin="auto" data-aos="fade-in">
              <Img fluid={data.LogoPoweredBy.childImageSharp.fluid} />
            </Box>
          </Hidden>
        </Box>
      </Box>
    </>
  );
}

export default PowerdBy;
