import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/normal_fotter";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import Concept from "../components/concept";
import Place from "../components/place";
import Team from "../components/team";
import PowerdBy from "../components/powerdBy";
import News from "../components/newpointNews";
import Mirabura from "../components/Mirabura";
import NoteAndInstgram from "../components/NoteAndInstgram";
import Contact from "../components/Contact";
import Popup from "../components/Popup";
import InstagramEmbed from "../components/InstagramEmbed";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fv: file(relativePath: { eq: "fv_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#fff">
        <Header />
        <Box pt={[4, 6, 10]} data-aos="fade-in">
          <video
            width="100%"
            muted
            muted={true}
            autoPlay
            // loop
            preLoad
            webkitPlaysinline
            playsInline
            src="/FV.mp4"
            type="video/mp4"
            style={{ marigin: 0, padding: 0 }}
          />
        </Box>
        <Concept />
        <Box id="place"></Box>
        <Place />
        <Box bgcolor="#FCFAF7">
          <NoteAndInstgram />
        </Box>
        <Box id="mirabura">
          <Mirabura />
        </Box>
        <Box id="pop-up-store" bgcolor="#fff" />
        <Popup />
        <Box id="news" />
        <News />
        <Box id="team" />
        <Team />
        <PowerdBy />
        <Box id="contact" />
        <Contact />
        <Footer />
      </Box>
    </>
  );
}
