import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";
import "../css/animation.css";

function NoteAndInstgram() {
  const data = useStaticQuery(graphql`
    query {
      instagramImg: file(relativePath: { eq: "instagram.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      noteImg: file(relativePath: { eq: "note.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      circle: file(relativePath: { eq: "circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dot: file(relativePath: { eq: "dot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Box
        position="relative"
        width={["80%", "80%", "80%"]}
        margin="auto"
        paddingBottom={["50px", "50px", "100px"]}
      >
        <Box position="relative" zIndex="10">
          <Box maxWidth="1200px" margin="auto">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <Box display="flex" justifyContent="center" mb={[1, 1, 2]}>
                    <Box>
                      <Box width="21px" marginLeft="-25px">
                        <Img
                          fluid={data.dot.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                      <Box whiteSpace={["normal", "normal", "pre-line"]}>
                        <WrapTypo
                          fs="16px"
                          xsfs="12px"
                          mdfs="10px"
                          lgfs="16px"
                          ls="1px"
                          lh="200%"
                          style={{ textAlign: "justify" }}
                        >
                          {`NEW POINTを、もっと知りたい方はnoteへ。
                    様々な『みらブラ』を知れる場所です。`}
                        </WrapTypo>
                      </Box>
                    </Box>
                  </Box>
                  <Link
                    to="https://note.com/newpoint_pj"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box maxWidth="500px" margin="auto">
                      <Img
                        fluid={data.noteImg.childImageSharp.fluid}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <Box display="flex" justifyContent="center" mb={[1, 1, 2]}>
                    <Box>
                      <Box width="21px" marginLeft="-25px">
                        <Img
                          fluid={data.dot.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                      <Box whiteSpace={["normal", "normal", "pre-line"]}>
                        <WrapTypo
                          fs="16px"
                          xsfs="12px"
                          mdfs="10px"
                          lgfs="16px"
                          ls="1px"
                          lh="200%"
                          style={{ textAlign: "justify" }}
                        >
                          {`NEW POINTを、まず知りたい方はInstagramへ。
                    様々な『みらブラ』に出会える場所です。`}
                        </WrapTypo>
                      </Box>
                    </Box>
                  </Box>
                  <Link
                    to="https://www.instagram.com/newpoint_pj/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box maxWidth="500px" margin="auto">
                      <Img
                        fluid={data.instagramImg.childImageSharp.fluid}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          position="absolute"
          zIndex={1}
          width={["60px", "60px", "140px"]}
          bottom={["-65px", "-65px", "-80px"]}
          right={["0", "0", "-60px"]}
          id="rotationAnimation1"
        >
          <Img
            fluid={data.circle.childImageSharp.fluid}
            style={{ width: "100%" }}
          />
        </Box>
      </Box>
    </>
  );
}

export default NoteAndInstgram;
