import React from "react";
import Img from "gatsby-image";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/normal_fotter";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fv: file(relativePath: { eq: "fv_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      concept_img: file(relativePath: { eq: "concept_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      future: file(relativePath: { eq: "future.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden mdUp>
        <Box ml="5%" className="mb64 mt64　w95" data-aos-duration="3000">
          <Box className="w100" margin="30px 0 16px" data-aos="fade-in">
            <Img
              fluid={data.future.childImageSharp.fluid}
              className="w80 tac"
            />
          </Box>
          <WrapTypo
            fs="12px"
            ls="2px"
            lh="200%"
            className="w90"
            data-aos="fade-in"
          >
            This is our new point.
            <br />
            ここはわたしたちの新しい地点。
            <br />
            <br />
            NEW POINTという真っ白なキャンバスの上に、
            たくさんの人々が理想とする社会を自由に描いていく。
            <br />
            <br />
            初めて見る景色、出会った人々、様々な価値観から
            思い想いの好きな色を塗ることができる。
            <br />
            <br />
            ”本当に良いものを思いを込めて届ける”
            <br /> ”本当に良いものを好きになる”
            <br />
            <br />
            この場所に、みんなの好きが集まって
            <br />
            それぞれの「未来を、もっとカラフルに。」してくれると
            信じているから。
            <br />
          </WrapTypo>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box className="tac mb128 mt128">
          <Box
            width="100%"
            margin="30px 0 16px"
            data-aos="fade-in"
            data-aos-duration="3000"
          >
            <Img
              fluid={data.future.childImageSharp.fluid}
              className="w80 tac ma"
              style={{ maxWidth: "518px" }}
            />
          </Box>
          <WrapTypo
            fs="16px"
            ls="2px"
            lh="200%"
            data-aos="fade-in"
            data-aos-duration="3000"
          >
            This is our new point.ここはわたしたちの新しい地点。
            <br />
            <br />
            NEW POINTという真っ白なキャンバスの上に、
            <br />
            たくさんの人々が理想とする社会を自由に描いていく。
            <br />
            <br />
            初めて見る景色、出会った人々、様々な価値観から
            <br />
            思い想いの好きな色を塗ることができる。
            <br />
            <br />
            ”本当に良いものを思いを込めて届ける”
            <br /> ”本当に良いものを好きになる”
            <br />
            <br />
            この場所に、みんなの好きが集まって
            <br />
            それぞれの「未来を、もっとカラフルに。」してくれると信じているから。
            <br />
          </WrapTypo>
        </Box>
      </Hidden>
    </>
  );
}
