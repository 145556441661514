import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { ContactForm } from "../components/ContactForm";
import { ContactTitle } from "../components/ContactTitle";
import WrapTypo from "../components/WrapTypo";
import Box from "@material-ui/core/Box";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/normal_fotter";
import { useStaticQuery, graphql, Link } from "gatsby";

const Contacts = () => {
  return (
    <>
      <Head />
      <Header />
      <Box
        width="100%"
        textAlign="center"
        height="70vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <WrapTypo>
            お問い合わせありがとうございます。
            <br />
            ３営業日以内に返信させて頂きます。
            <br />
            <br />
            <Link
              to="/"
              style={{ color: "#717171", borderBottom: "1px solid #717171" }}
            >
              TOPへ
            </Link>
          </WrapTypo>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Contacts;
