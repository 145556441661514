import React from "react"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"
import { ContactForm } from "../components/ContactForm"
import { ContactTitle } from "../components/ContactTitle"

import Head from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import Box from "@material-ui/core/Box"

const Contacts = () => {
  return (
    <>
      <Head />
      <Box bgcolor="#F6F4F2">
        <Header />
        <Grid
          container
          style={{
            minHeight: "50px",
            backgroundColor: "#F6F4F2",
          }}
        >
          <Grid item xs={12}>
            <ContactTitle />
            <ContactForm />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default Contacts
