import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import WrapTypo from "./WrapTypo";
import "../css/news.css";

function PowerdBy() {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNewpoint {
        edges {
          node {
            date
            title
            newpointId
          }
        }
      }
    }
  `);

  const newsContent = data.allMicrocmsNewpoint.edges.reverse();

  const newsContens = [
    { date: "2020.02.02", title: "test" },
    { date: "2020.02.02", title: "test" },
    { date: "2020.02.02", title: "test" },
  ];

  return (
    <>
      <Box bgcolor="#ffffff" position="relative" zIndex={1}>
        <Box pt={8} pb={10}>
          <Box width={["90%", "80%"]} margin="auto">
            <Box data-aos="fade-in">
              <WrapTypo
                Luam
                fs="80px"
                xsfs="40px"
                mdfs="40px"
                lgfs="80px"
                ls="2px"
                lh="200%"
              >
                NEWS
              </WrapTypo>
            </Box>

            <Hidden xsDown>
              <Box data-aos="fade-in">
                {newsContent.map((pic) => {
                  return (
                    <Box width="100%" borderBottom={1} borderColor="#14274C">
                      <Link to={"/news/" + pic.node.newpointId}>
                        <Box display="flex">
                          <Box
                            borderRight={1}
                            borderColor="#14274C"
                            margin="25px 0"
                            padding="41px 62px"
                          >
                            <WrapTypo
                              fs="16px"
                              xsfs="16px"
                              mdfs="16px"
                              lgfs="16px"
                              ls="2px"
                              lh="200%"
                            >
                              {pic.node.date}
                            </WrapTypo>
                          </Box>
                          <Box
                            padding="66px 62px"
                            width="100%"
                            display="flex"
                            alignItems="center"
                          >
                            <WrapTypo
                              fs="14px"
                              xsfs="10px"
                              mdfs="10px"
                              lgfs="14px"
                              ls="2px"
                              lh="200%"
                            >
                              {pic.node.title}
                            </WrapTypo>
                          </Box>
                          <Box
                            padding="66px 62px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box width="45px">
                              <Img fluid={data.arrow.childImageSharp.fluid} />
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Hidden>
            <Hidden smUp>
              <Box data-aos="fade-in">
                {newsContent.map((pic) => {
                  return (
                    <Box width="100%" margin="0 0 40px">
                      <Link to={"/news/" + pic.node.newpointId}>
                        <Box>
                          <WrapTypo Notosans fs="16px" ls="2px" lh="200%">
                            {pic.node.date}
                          </WrapTypo>
                        </Box>
                        <Box width="100%" display="flex" alignItems="center">
                          <WrapTypo Notosans fs="14px" ls="2px" lh="200%">
                            {pic.node.title}
                          </WrapTypo>
                        </Box>
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PowerdBy;
