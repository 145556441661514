import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../css/base.css";
import "../css/animation.css";

const Place = () => {
  const data = useStaticQuery(graphql`
    query {
      note: file(relativePath: { eq: "newpoint_note.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      place1: file(relativePath: { eq: "place1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      place2: file(relativePath: { eq: "place2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      place3: file(relativePath: { eq: "place3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "cycle_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box pb={[8, 8, 16]} pt={[7]} bgcolor="#FCFAF7">
        <Box ml={["5%", "5%", "10%"]} data-aos="fade-in">
          <WrapTypo Luam fs="80px" xsfs="40px" ls="10px" lh="100%">
            PLACE
          </WrapTypo>
          <WrapTypo fs="18px" mt="10px" ls="2px" lh="110%" className="smt32">
            NEW POINTが目指す場所
          </WrapTypo>
        </Box>
        <Grid container justify="space-around" className="tac w90 ma">
          <Grid item xs={0} md={2}>
            <Box id="rotationAnimation3">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                className="w80 mt256"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              maxWidth="300px"
              margin="auto"
              data-aos="fade-in"
              data-aos-duration="3000"
              data-aos-delay="100"
            >
              <Img
                fluid={data.place1.childImageSharp.fluid}
                className="smt64 smw70 ma"
              />
              <WrapTypo Luam fs="60px" ls="2px" lh="200%">
                01
              </WrapTypo>
              <WrapTypo fs="20px" ls="2px" lh="110%">
                新しい自分を見つける
              </WrapTypo>
              <Box width="95%" margin="auto">
                <WrapTypo
                  fs="14px"
                  ls="2px"
                  lh="150%"
                  className="taj w100"
                  mt="16px"
                >
                  NEW
                  POINTは、新しい発見と隠れた欲求を満たし、自分の新しい”好き”と出会える場所です。
                </WrapTypo>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              maxWidth="300px"
              margin="auto"
              data-aos="fade-in"
              data-aos-duration="3000"
              data-aos-delay="150"
            >
              <Img
                fluid={data.place2.childImageSharp.fluid}
                className="mt128 smt64 smw70 ma"
              />
              <WrapTypo Luam fs="60px" ls="2px" lh="200%">
                02
              </WrapTypo>
              <WrapTypo fs="20px" ls="2px" lh="110%">
                日常を彩る
              </WrapTypo>
              <Box width="95%" margin="auto">
                <WrapTypo
                  fs="14px"
                  ls="2px"
                  lh="150%"
                  className="taj w100"
                  mt="16px"
                >
                  NEW
                  POINTは、真っ白なキャンバスです。共に”好きな色”で人生を彩っていく場所です。
                </WrapTypo>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              maxWidth="300px"
              margin="auto"
              data-aos="fade-in"
              data-aos-duration="3000"
              data-aos-delay="300"
            >
              <Img
                fluid={data.place3.childImageSharp.fluid}
                className="smt64 smw70 ma"
              />
              <WrapTypo Luam fs="60px" ls="2px" lh="200%">
                03
              </WrapTypo>
              <WrapTypo fs="20px" ls="2px" lh="110%">
                繋がりを広げる
              </WrapTypo>
              <Box width="95%" margin="auto">
                <WrapTypo
                  fs="14px"
                  ls="2px"
                  lh="150%"
                  className="taj w100"
                  mt="16px"
                >
                  NEW
                  POINTは、1人1人の”好き”が繋がり、広がっていくことで新しい出会いを創る場所です。
                </WrapTypo>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Place;
