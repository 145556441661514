import React from "react"
import { navigate } from "gatsby"
import { Grid, Box, Button } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useForm } from "react-hook-form"
import firebase from "../Firebase"
import Wraptypo from "../components/WrapTypo"
import { getThemeProps } from "@material-ui/styles"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const isEmpty = obj => !Object.keys(obj).length

const emailRule = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "「メールアドレス」の入力形式が正しくありません。",
  },
}
const phoneNumberRule = {
  minLength: {
    value: 6,
    message: "「電話番号」の入力文字数が少なすぎます",
  },
  maxLength: {
    value: 12,
    message: "「電話番号」の入力文字数が多すぎます",
  },
  pattern: {
    value: /^[0-9]+$/,
    message: "「電話番号」の入力形式が正しくありません",
  },
}

const textRule = name => ({
  maxLength: {
    value: 50,
    message: `「${name}」の入力文字数が多すぎます`,
  },
})

const contextRule = {
  maxLength: {
    value: 1000,
    message: "「内容」入力文字数が多すぎます",
  },
}

const positionRule = {
  maxLength: {
    value: 100,
    message: "「Position/役職」の入力文字数が多すぎます",
  },
}

export const ContactForm = () => {
  const { handleSubmit, register, errors } = useForm()
  const [loading, setLoading] = React.useState(false)
  const onSubmit = values => {
    setLoading(true)
    const docId = firebase.firestore().collection("contacts").doc().id
    firebase
      .firestore()
      .collection("contacts")
      .doc(docId)
      .set({
        ...values,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        navigate("/contacts-result/", { state: { result: "success" } })
        setLoading(false)
      })
      .catch(e => {
        navigate("/contacts-result/", { state: { result: "faild" } })
        setLoading(false)
      })
  }

  const errKeys = Object.keys(errors)
  return (
    <>
      <Box width="100%" maxWidth="1080px" margin="auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {!isEmpty(errors) && errKeys.length > 0 && (
              <Box mb={2}>
                <Alert severity="error">
                  {errKeys.map(k => (
                    <React.Fragment key={k}>
                      ・{errors[k].message}
                      <br />
                    </React.Fragment>
                  ))}
                </Alert>
              </Box>
            )}
            <Box width="100%" padding="0 30px">
              <GridResponsiveSpace>
                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <InputFormItem
                      title="Name/名前"
                      placeholder="お名前"
                      name="company"
                      register={register}
                      rule={textRule("会社名")}
                      requiredItemFlag={true}
                      subTitle="入力必須項目です。"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <InputFormItem
                      title="Company/会社名・所属組織名"
                      placeholder="一般社団法人Public Meets Innovation"
                      name="name"
                      register={register}
                      rule={textRule("お名前")}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <InputFormItem
                      title="Position/役職"
                      placeholder="役職"
                      name="phoneNumber"
                      register={register}
                      rule={positionRule}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <InputFormItem
                      name="mail"
                      title="Mail/メールアドレス"
                      placeholder="email@example"
                      register={register}
                      rule={emailRule}
                      requiredItemFlag={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <InputFormItem
                      name="tel"
                      title="Tel/電話番号"
                      placeholder="090xxxxxxxx"
                      register={register}
                      rule={phoneNumberRule}
                      requiredItemFlag={true}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box style={{ textAlign: "left" }}>
                    <SelectFormItem
                      name="employeeRange"
                      title="依頼内容"
                      optionsText={["-", "取材", "講演", "お仕事", "その他"]}
                      register={register}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box style={{ textAlign: "left" }}>
                    <TextAreaFormItem
                      name="context"
                      title="Inquiry/お問い合わせ内容"
                      placeholder="内容を記入してください"
                      register={register}
                      rule={contextRule}
                      requiredItemFlag={true}
                    />
                  </Box>
                </Grid>
              </GridResponsiveSpace>
            </Box>
            <Box textAlign="center">
              {!loading ? (
                <Button
                  type="submit"
                  style={{ backgroundColor: "#80B7F5", borderRadius: "0" }}
                >
                  <Wraptypo mt={"8px"} mb={"8px"} color="#ffffff">
                    入力内容を送信
                  </Wraptypo>
                </Button>
              ) : (
                <Button
                  disabled={true}
                  type="submit"
                  style={{ backgroundColor: "#80B7F5", borderRadius: "0" }}
                >
                  <Wraptypo mt={"8px"} mb={"8px"} color="#ffffff">
                    送信中
                  </Wraptypo>
                </Button>
              )}
            </Box>
          </>
        </form>
      </Box>
      <Box mb={4} />
    </>
  )
}

const InputFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  requiredItemFlag,
  subTitle,
}) => {
  return (
    <>
      <Grid container>
        <Box width="100%">
          <Grid item>
            <Wraptypo fs="14px">
              {title}
              <span
                style={
                  requiredItemFlag ? { color: "red" } : { display: "none" }
                }
              >
                *
              </span>{" "}
              {subTitle}
            </Wraptypo>
          </Grid>
          <Grid item>
            <input
              type="text"
              name={name}
              ref={register({
                required: `「${title}」の項目が未入力です`,
                ...rule,
              })}
              placeholder={placeholder}
              style={{
                height: "30px",
                fontSize: "16px",
                width: "100%",
                border: "0",
                fontSize: "14px",
                padding: "0 10px",
              }}
            />
          </Grid>
        </Box>
      </Grid>
      <Box mb={4} />
    </>
  )
}

const TextAreaFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  requiredItemFlag,
  subTitle,
}) => {
  return (
    <>
      <Grid container>
        <Box width="100%">
          <Wraptypo fs="14px">
            {title}
            <span
              style={requiredItemFlag ? { color: "red" } : { display: "none" }}
            >
              *
            </span>{" "}
            {subTitle}
          </Wraptypo>
          <Grid item>
            <textarea
              name={name}
              ref={register({
                required: `「${title}」の項目が未入力です`,
                ...rule,
              })}
              placeholder={placeholder}
              style={{
                height: "100px",
                fontSize: "16px",
                width: "100%",
                border: "0",
                fontSize: "14px",
                padding: "5px 10px",
              }}
            />
          </Grid>
        </Box>
      </Grid>
      <Box mb={4} />
    </>
  )
}

const SelectFormItem = ({
  title,
  optionsText,
  register,
  name,
  requiredItemFlag,
  subTitle,
}) => {
  return (
    <>
      <Grid container>
        <Box width="100%">
          <Grid item>
            <Wraptypo color="#999" fs="14px">
              {title}
              <span
                style={
                  requiredItemFlag ? { color: "red" } : { display: "none" }
                }
              >
                *
              </span>{" "}
              {subTitle}
            </Wraptypo>
          </Grid>
          <Grid item>
            <select
              name={name}
              ref={register({ required: `「${title}」の項目が未選択です` })}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "16px",
                border: "0",
                fontSize: "14px",
              }}
            >
              {optionsText.map(x => (
                <option key={x}>{x}</option>
              ))}
            </select>
          </Grid>
        </Box>
      </Grid>
      <Box mb={4} />
    </>
  )
}

const GridResponsiveSpace = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Grid container spacing={isSmall ? 0 : 3}>
      {children}
    </Grid>
  )
}
