import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../css/base.css";
import BoxTypo from "../components/BoxTypo";

const Footer = () => {
  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      newpointt: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      note: file(relativePath: { eq: "newpointNote.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagram: file(relativePath: { eq: "newpointInstagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mituiLogo: file(relativePath: { eq: "mituiLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden smDown>
        <Box display="flex" width="100%" bgcolor="#F7F6F1">
          <Box
            width="80%"
            borderTop="1px solid #1A2D51"
            borderLeft="1px solid #1A2D51"
            borderBottom="1px solid #1A2D51"
          >
            <Grid container className="w100" alignItems="center">
              <Grid item xs={4}>
                <Box ml="13%">
                  <Img
                    fluid={data.newpointt.childImageSharp.fluid}
                    className="w80"
                  />
                </Box>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={3}>
                <Box borderLeft="1px solid #1A2D51" width="100%">
                  <Link
                    to="https://note.com/newpoint_pj"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={data.note.childImageSharp.fluid} />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Link
                  to="https://www.instagram.com/newpoint_pj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fluid={data.instagram.childImageSharp.fluid} />
                </Link>
              </Grid>
            </Grid>
            <Box borderTop="1px solid #1A2D51">
              <Box height="160px" display="flex">
                <Grid
                  container
                  className="w80 tac"
                  alignItems="center"
                  alignContent="center"
                  style={{ height: "100%" }}
                >
                  <Grid item xs={2}>
                    <AnchorLink to="/#place">
                      <WrapTypo Luam fw="700" ls="2px" fs="20px">
                        PLACE
                      </WrapTypo>
                      <WrapTypo Notosans fs="10px">
                        提供する価値
                      </WrapTypo>
                    </AnchorLink>
                  </Grid>
                  <Grid item xs={2}>
                    <AnchorLink to="/#mirabura">
                      <WrapTypo Luam fw="700" ls="2px" fs="20px">
                        MIRABURA
                      </WrapTypo>
                      <WrapTypo Notosans fs="10px">
                        みらブラ紹介
                      </WrapTypo>
                    </AnchorLink>
                  </Grid>
                  <Grid item xs={2}>
                    <AnchorLink to="/#pop-up-store">
                      <WrapTypo Luam fw="700" ls="2px" fs="20px">
                        POP-UP STORE
                      </WrapTypo>
                      <WrapTypo Notosans fs="10px">
                        ポップアップストア
                      </WrapTypo>
                    </AnchorLink>
                  </Grid>
                  <Grid item xs={2}>
                    <AnchorLink to="/#news">
                      <WrapTypo Luam fw="700" ls="2px" fs="20px">
                        NEWS
                      </WrapTypo>
                      <WrapTypo Notosans fs="10px">
                        最新情報
                      </WrapTypo>
                    </AnchorLink>
                  </Grid>
                  <Grid item xs={2}>
                    <Link to="/#team">
                      <WrapTypo Luam fw="700" ls="2px" fs="20px">
                        TEAM
                      </WrapTypo>
                      <WrapTypo Notosans fs="10px">
                        チーム紹介
                      </WrapTypo>
                    </Link>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
                <Box width="20%" display="flex" alignItems="flex-end">
                  <Box>
                    <Box width="169px">
                      <Link
                        to="https://www.mitsuifudosan.co.jp/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={data.mituiLogo.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box height="45px">
                <Box display="flex" ml="4.3%" mt="5px">
                  <Link
                    to="https://www.mitsuifudosan.co.jp/privacy_policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BoxTypo fs="10px" style={{ color: "#979797" }} mr="10px">
                      個人情報保護方針
                    </BoxTypo>
                  </Link>
                  <Link
                    to="https://www.mitsuifudosan.co.jp/basic_personal_policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BoxTypo fs="10px" style={{ color: "#979797" }} mr="10px">
                      特定個人情報基本方針
                    </BoxTypo>
                  </Link>

                  <Link
                    to="https://newpoint.web.app/personal-information.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BoxTypo fs="10px" style={{ color: "#979797" }} mr="10px">
                      個人情報の取り扱いについて
                    </BoxTypo>
                  </Link>
                  <Link
                    to="https://www.mitsuifudosan.co.jp/privacy_policy/cookie_accesslog/index.html?id=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BoxTypo fs="10px" style={{ color: "#979797" }} mr="10px">
                      Cookieおよびアクセスログについて
                    </BoxTypo>
                  </Link>
                  <Link
                    to="https://newpoint.web.app/notice.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BoxTypo fs="10px" style={{ color: "#979797" }} mr="10px">
                      サイトご利用上の注意
                    </BoxTypo>
                  </Link>
                </Box>
                <WrapTypo Notosans fs="10px" ml="4.3%" ls="1px">
                  Copyright All Rights Reserved.
                </WrapTypo>
              </Box>
            </Box>
          </Box>
          <Box
            width="20%"
            bgcolor="#FF9775"
            border="1px solid #1A2D51"
            display="flex"
            alignItems="center"
          >
            <Box width="90%" margin="auto">
              <BoxTypo mb="15px" fs="28px" style={{ color: "#F7F6F1" }}>
                関連サイト
              </BoxTypo>
              <Box mb="18px">
                <Link
                  to="https://www.mitsuifudosan.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="14px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    三井不動産オフィシャルサイト
                  </BoxTypo>
                </Link>
              </Box>
              <Box mb="18px">
                <Link
                  to="https://www.instagram.com/newpoint_pj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="14px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    NEW POINT公式インスタグラム
                  </BoxTypo>
                </Link>
              </Box>
              <Box mb="18px">
                <Link
                  to="https://note.com/newpoint_pj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="14px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    NEW POINT公式note
                  </BoxTypo>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box bgcolor="#F7F6F1" pt={2}>
          <Img
            fluid={data.newpointt.childImageSharp.fluid}
            className="w50 ma tac "
            style={{ marginBottom: "37px", marginTop: "37px" }}
          />
          <Box className="df">
            <Box className="w50">
              <Link
                to="https://note.com/newpoint_pj"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.note.childImageSharp.fluid} />
              </Link>
            </Box>
            <Box className="w50">
              <Link
                to="https://www.instagram.com/newpoint_pj/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.instagram.childImageSharp.fluid} />
              </Link>
            </Box>
          </Box>
          <Box ml="10%" py={5} position="relative">
            <AnchorLink to="/#place">
              <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="12px">
                PLACE
              </WrapTypo>
              <WrapTypo Notosans fs="8px">
                提供する価値
              </WrapTypo>
            </AnchorLink>
            <AnchorLink to="/#mirabura">
              <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="12px">
                MIRABURA
              </WrapTypo>
              <WrapTypo Notosans fs="8px">
                みらブラ紹介
              </WrapTypo>
            </AnchorLink>

            <AnchorLink to="/#pop-up-store">
              <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="12px">
                POP-UP STORE
              </WrapTypo>
              <WrapTypo Notosans fs="8px">
                ポップアップストア
              </WrapTypo>
            </AnchorLink>

            <AnchorLink to="/#news">
              <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="12px">
                NEWS
              </WrapTypo>
              <WrapTypo Notosans fs="8px">
                最新情報
              </WrapTypo>
            </AnchorLink>

            <Link to="/#team">
              <WrapTypo Luam fw="700" ls="2px" mt="16px" fs="12px">
                TEAM
              </WrapTypo>
              <WrapTypo Notosans fs="8px">
                チーム紹介
              </WrapTypo>
            </Link>

            <Link
              to="https://www.mitsuifudosan.co.jp/privacy_policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxTypo mt="10px" fs="8px" ls="1px" style={{ color: "#979797" }}>
                個人情報保護方針
              </BoxTypo>
            </Link>
            <Link
              to="https://www.mitsuifudosan.co.jp/basic_personal_policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxTypo fs="8px" ls="1px" style={{ color: "#979797" }}>
                特定個人情報基本方針
              </BoxTypo>
            </Link>
            <Link
              to="https://newpoint.web.app/personal-information.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxTypo fs="8px" ls="1px" style={{ color: "#979797" }}>
                個人情報の取り扱いについて
              </BoxTypo>
            </Link>
            <Link
              to="https://www.mitsuifudosan.co.jp/privacy_policy/cookie_accesslog/index.html?id=footer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxTypo fs="8px" ls="1px" style={{ color: "#979797" }}>
                Cookieおよびアクセスログについて
              </BoxTypo>
            </Link>
            <Link
              to="https://newpoint.web.app/notice.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxTypo mb="16px" fs="8px" ls="1px" style={{ color: "#979797" }}>
                サイトご利用上の注意
              </BoxTypo>
            </Link>
            <Box width="116px" position="absolute" bottom="37px" right="24px">
              <Link
                to="https://www.mitsuifudosan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.mituiLogo.childImageSharp.fluid}
                  style={{ width: "100%" }}
                />
              </Link>
            </Box>
          </Box>
          <Box
            width="100%"
            bgcolor="#FF9775"
            border="1px solid #1A2D51"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={2}
          >
            <Box width="90%" margin="auto" mb="26px" mt="26px">
              <BoxTypo mb="5px" fs="16px" style={{ color: "#F7F6F1" }}>
                関連サイト
              </BoxTypo>
              <Box mb="5px">
                <Link
                  to="https://www.mitsuifudosan.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="10px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    三井不動産オフィシャルサイト
                  </BoxTypo>
                </Link>
              </Box>
              <Box mb="5px">
                <Link
                  to="https://www.instagram.com/newpoint_pj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="10px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    NEW POINT公式インスタグラム
                  </BoxTypo>
                </Link>
              </Box>
              <Box>
                <Link
                  to="https://note.com/newpoint_pj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BoxTypo
                    display="inline-block;"
                    fw="700"
                    fs="10px"
                    ls="2px"
                    lh="100%"
                    style={{
                      color: "#F7F6F1",
                      borderBottom: "1px solid #F7F6F1",
                    }}
                  >
                    NEW POINT公式note
                  </BoxTypo>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box py={2} className="tac">
            <WrapTypo Notosans fs="12px" ls="2px">
              Copyright All Rights Reserved.
            </WrapTypo>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default Footer;
